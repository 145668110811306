 <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Services</h2>
        <p>Depuis plus de 27 ans, nous avons construit une véritable expertise dans l'accompagnement des transformations digitales de nos clients, nous permettant d'adapter SAGE X3 à vos besoins spécifiques.</p>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch"
        data-aos="zoom-in" data-aos-delay="100"
        *ngFor = "let s of listServices">
          <div class="icon-box">
            <div class="icon" align="center">
              <img [src] = "s.serviceImg" width="150" height="100"/>
            </div>
            <h4 style="text-align: center;"><a href="">{{s.serviceTitle}}</a></h4>
            <p>{{s.serviceDesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->