<div class="container">
        <h3 class="logo me-auto text-center mt-5"><a href="/">STAR ENGINEERING</a></h3>
    <div class="card o-hidden border-0 shadow-lg my-5">
        <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
                <div class="col-lg-5 d-none d-lg-block" style="background-image: url(assets/img/starE.png);"></div>
                <div class="col-lg-7">
                    <div class="p-5">
                        <div class="text-center">
                            <h1 class="h4 text-gray-900 mb-4">Créer un compte!</h1>
                        </div>
                        <form class="user" [formGroup] = "signUpForm">
                            <div class="form-group row">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-user" id="exampleUsername"
                                        placeholder="Client" formControlName = "username">
                                </div>     
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control form-control-user" id="exampleInputEmail"
                                    placeholder="Adresse e-mail" formControlName = "email">
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <input type="password" class="form-control form-control-user"
                                        id="exampleInputPassword" placeholder="Mot de passe" formControlName = "password">
                                </div>
                                <div class="col-sm-6">
                                    <input type="password" class="form-control form-control-user"
                                        id="exampleRepeatPassword" placeholder="Répéter le mot de passe"
                                        formControlName = "confirmPassword">
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control form-control-user" id="serialCtr"
                                        placeholder="Code de contrat en série" formControlName = "serialCtr">
                            </div>
                            <div class="form-group">
                                <input type="number" class="form-control form-control-user" id="phoneNumber"
                                        placeholder="Numéro de téléphone" formControlName = "phoneNumber">
                            </div>
                            <a (click) = "signUp()" class="btn btn-primary btn-user btn-block">
                                Créer un compte
                            </a>
                            <hr>
                            <!-- <a href="index.html" class="btn btn-google btn-user btn-block">
                                <i class="fab fa-google fa-fw"></i> Register with Google
                            </a>
                            <a href="index.html" class="btn btn-facebook btn-user btn-block">
                                <i class="fab fa-facebook-f fa-fw"></i> Register with Facebook
                            </a> -->
                        </form>
                       
                        <div class="text-center">
                            <a class="small" routerLink="/login">Vous avez déjà un compte? Connexion!</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
