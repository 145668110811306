import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-messages-admin',
  templateUrl: './messages-admin.component.html',
  styleUrls: ['./messages-admin.component.css']
})
export class MessagesAdminComponent implements OnInit {

  listMessages: Object[] = [];

  constructor(private messageService: MessageService) { }

  deleteMsg(msgId) {
    if (confirm("Are you sure you would like to delete this message?")) {
      this.messageService.deleteMsg(msgId).subscribe(data => {
        alert("Message Deleted!");
        window.location.reload();
      })
    }
  }

  getListMessages() {
    this.messageService.getMessages().subscribe((messages: Object[]) => {
      this.listMessages = messages;
    }, error => {
      alert("Error finding messages");
    })
  }

  ngOnInit(): void {
    this.getListMessages();
  }

}
