import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit {

  signUpForm: FormGroup;
  dataGot: any = [];
  users: any;
  verifInfo: String = '';
  userSignedUp: any;

  constructor(private router: Router, private userService: UserService,
    private adminService: AdminService,
    private formBuilder: FormBuilder, private elementRef: ElementRef) { 
      this.signUpForm = formBuilder.group({
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        serialCtr: ['', Validators.required],
        phoneNumber: ['', Validators.required]
      }, {
        validator: this.ConfirmedValidator('password', 'confirmPassword')
      })
    }

    getAllUsers() {
      this.adminService.getUsers().subscribe(users => {
        this.dataGot = users
      })
    }

    ConfirmedValidator(controlName: string, matchingControlName: string){
      return (formGroup: FormGroup) => {
          const control = formGroup.controls[controlName];
          const matchingControl = formGroup.controls[matchingControlName];
          if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
              return;
          }
          if (control.value !== matchingControl.value) {
              matchingControl.setErrors({ confirmedValidator: true });
          } else {
              matchingControl.setErrors(null);
          }
      }}
      
      signUp() {
        this.userSignedUp = { 
          username: this.signUpForm.value.username,
          email: this.signUpForm.value.email, 
          password: this.signUpForm.value.password,
          passwordCheck: this.signUpForm.value.confirmPassword,
          serialCtr: this.signUpForm.value.serialCtr,
          phoneNumber: this.signUpForm.value.phoneNumber
           }
           this.userService.registerUser(this.userSignedUp).subscribe(data => {
            alert("Account created successfully !\nYou will be redirected to the login page !");
            this.signUpForm.reset();
            this.router.navigate(['/login']);
          }, err => {
            alert(err.error);
          });
          }
        

  ngOnInit(): void {
    this.getAllUsers();
  }

  ngAfterViewInit() {
    /* this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = '#3A60D0'; */
  }

}
