import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  addNewNews(news) {
    return this.http.post(`${environment.starEngBackendURL}news/add-news`, news, this.httpOptions);
  }

  getListNews() {
    return this.http.get(`${environment.starEngBackendURL}news/allNews`, this.httpOptions);
  }

  deleteNews(newsId) {
    return this.http.delete(`${environment.starEngBackendURL}news/delete-news/${newsId}`, this.httpOptions);
  }
}
