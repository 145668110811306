import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  addNewService(service) {
    return this.http.post(`${environment.starEngBackendURL}service/add-service`, service, this.httpOptions);
  }

  getListServices() {
    return this.http.get(`${environment.starEngBackendURL}service/allServices`, this.httpOptions);
  }

  deleteService(serviceId) {
    return this.http.delete(`${environment.starEngBackendURL}service/delete-service/${serviceId}`, this.httpOptions);
  }
}
