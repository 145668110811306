import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getUsers() {
    return this.httpClient.get(`${environment.starEngBackendURL}admin/allUsers`, this.httpOptions);
  }

  acceptUser(userId) {
    return this.httpClient.put(`${environment.starEngBackendURL}admin/approve-user/${userId}`, this.httpOptions);
  }
  verifyUser(emails) {
    return this.httpClient.get(`${environment.starEngBackendURL}admin/verify-email?email=${emails}`, this.httpOptions);
  }
  rejectUser(userId) {
    return this.httpClient.delete(`${environment.starEngBackendURL}admin/reject-user/${userId}`, this.httpOptions);
  }
  deleteUsers(userId) {
    return this.httpClient.delete(`${environment.starEngBackendURL}admin/delete-user-account/${userId}`, this.httpOptions);
  }
  addUser(usr) {
    return this.httpClient.post(`${environment.starEngBackendURL}admin/add-new-user`, usr, this.httpOptions);
  }



}
