<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <div align = "right">
                    <a routerLink = "/admin/messages">
                      <button class = "btn btn-primary">
                         Messages
                      </button>
                    </a>
                </div>
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Répondre au message</h1>
                </div>
                <form [formGroup] = "replyForm">
                    <div class="form-group">
                        <input type="text" formControlName = "username"
                        class="form-control form-control-user" placeholder="client">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "email"
                        class="form-control form-control-user" placeholder="email">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "subject"
                        class="form-control form-control-user" placeholder="subject">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "content"
                        class="form-control form-control-user" placeholder="content">
                    </div>
                    <a (click)="SendMsg()" class="btn btn-primary btn-user btn-block">
                        Envoyer le message
                    </a>
                </form>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>
