import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  msgForm: FormGroup;
  newMsg;
  data: FormData;
  constructor(private formBuilder: FormBuilder, private contactService: ContactService,
    private router: Router) { 
      this.msgForm = this.formBuilder.group({
        username: ['', Validators.required],
        email: ['', Validators.required],
        subject: ['', Validators.required],
        content: ['', Validators.required]
      })
     }

     SendMSG(): void {
      this.data = new FormData();
       
            this.newMsg = {
              username: this.msgForm.value.username,
              email: this.msgForm.value.email,
              subject: this.msgForm.value.subject,
              content: this.msgForm.value.content
            }
            this.contactService.SendMSG(this.newMsg).subscribe(() => {
              this.msgForm.reset();
              alert("message envoyer avec succés");
              this.router.navigateByUrl("/login");
            }, err => {
              alert("message non envoyer");
            });
      }
    
    ngOnInit(): void {
    }
  
  }
  