import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {

  newsForm: FormGroup;
  newNews;
  data: FormData;
  file: any;
  urlImage: String;
  publicId: String;

  constructor(private formBuilder: FormBuilder, private newsService: NewsService,
    private router: Router) { 
      this.newsForm = this.formBuilder.group({
        newsTitle: ['', Validators.required],
        newsDesc: ['', Validators.required],
        newsImg: ['', Validators.required]
      })
     }

     uploadChange(event) {
      this.file = event.target.files[0];
      console.log(event);
     }

    addNewNews(): void {
      this.data = new FormData();
      this.data.append("file", this.file);
      this.data.append("upload_preset", "qjpkv9me");
      this.data.append("cloud_name", "dj9ufxhbc");
      fetch("https://api.cloudinary.com/v1_1/dj9ufxhbc/image/upload",{
              method: 'post',
              body: this.data
          }).then(resp => resp.json())
          .then(data => {
            this.urlImage = data.url;
            this.publicId = data.public_id;
            this.newNews = {
              newsTitle: this.newsForm.value.newsTitle,
              newsDesc: this.newsForm.value.newsDesc,
              newsImg: this.urlImage,
              newsPublicId: this.publicId
            }
            this.newsService.addNewNews(this.newNews).subscribe(() => {
              this.newsForm.reset();
              alert("News Added Successfully");
              this.router.navigateByUrl("/admin/news");
            }, error => {
              alert('Error adding news!');
            })
        })
    }

  ngOnInit(): void {
  }

}
