<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <div align = "right">
                    <a routerLink = "/admin/add-new-news">
                      <button class = "btn btn-primary">                 
                            Ajouter une nouvelle actualité
                      </button>
                    </a>
                </div>
                <h1 class="h3 mb-2 text-gray-800">Actualités</h1>
<p class="mb-4">Les actualités</p>
<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Nos Actualités</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>titre des actualités</th>
                        <th>Description des actualités</th>
                        <th>Image des actualités</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = "let e of listNews">
                        <td>{{e.newsTitle}}</td>
                        <td>{{e.newsDesc}}</td>
                        <td>
                            <img [src]="e.newsImg" width="150" height="100"/>
                        </td>
                        <td>
                            <button class = "btn btn-danger" (click)="deleteNews(e._id)">
                                <i class = "fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>

