import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  registerUser(user) {
    return this.httpClient.post(`${environment.starEngBackendURL}user/register`, user, this.httpOptions);
  }

  loginUser(user) {
    return this.httpClient.post(`${environment.starEngBackendURL}user/login`, user, this.httpOptions);
  }

}
