import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './dashboard/homepage/homepage.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ServicesAdminComponent } from './dashboard/services-admin/services-admin.component';
import { EventsAdminComponent } from './dashboard/events-admin/events-admin.component';
import { ReplymsgComponent } from './dashboard/replymsg/replymsg.component';

import { NewsAdminComponent } from './dashboard/news-admin/news-admin.component';
import { AddNewsComponent } from './dashboard/add-news/add-news.component';
import { MessagesAdminComponent } from './dashboard/messages-admin/messages-admin.component';
import { AddUsersComponent } from './dashboard/add-users/add-users.component';
import { AddServiceComponent } from './dashboard/add-service/add-service.component';
import { AddEventComponent } from './dashboard/add-event/add-event.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './dashboard/users/users.component';
import { UsersAdminComponent } from './dashboard/users-admin/users-admin.component';


const routes: Routes = [
  { path: 'admin', component: HomepageComponent },
  { path: 'admin/users', component: UsersComponent },
  { path: 'admin/services', component: ServicesAdminComponent },
  { path: 'admin/add-new-service', component: AddServiceComponent },
  { path: 'admin/events', component: EventsAdminComponent },
  { path: 'admin/messages', component: MessagesAdminComponent },
  { path: 'admin/usersAdmin', component: UsersAdminComponent },
  { path: 'admin/news', component: NewsAdminComponent },
  { path: 'admin/add-new-news', component: AddNewsComponent },
  { path: 'admin/add-new-user', component: AddUsersComponent },
  { path: 'admin/add-new-event', component: AddEventComponent },
  { path: 'admin/reply', component: ReplymsgComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: '', component: LandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
