import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.css']
})
export class AddUsersComponent implements OnInit {

  userForm: FormGroup;
  newUser;
  data: FormData;
  constructor(private formBuilder: FormBuilder, private adminService: AdminService,
    private router: Router) { 
      this.userForm = this.formBuilder.group({
        username: ['', Validators.required],
        email: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        serialCtr: ['', Validators.required]
      })
     }

     addNewUser(): void {
      this.data = new FormData();
       
            this.newUser = {
              username: this.userForm.value.username,
              email: this.userForm.value.email,
              phoneNumber: this.userForm.value.phoneNumber,
              serialCtr: this.userForm.value.serialCtr
            }
            this.adminService.addUser(this.newUser).subscribe(() => {
              this.userForm.reset();
              alert("utilisateur ajouter avec succés");
              this.router.navigateByUrl("/login");
            }, err => {
              alert("utilisateur non ajouter");
            });
      }
    
    ngOnInit(): void {
    }
  
  }
  