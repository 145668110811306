<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
     
                <h1 class="h3 mb-2 text-gray-800">Messages</h1>
<p class="mb-4">Messages qui se sont envoyer à notre application.</p>
<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Nos Messages</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>E-mail</th>
                        <th>subject</th>
                        <th>content</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = "let u of listMessages">
                        <td>{{u.username}}</td>
                        <td>{{u.email}}</td>
                        <td>{{u.subject}}</td>
                        <td>{{u.content}}</td>
                        <td>
                            <button class = "btn btn-danger" (click)="deleteMsg(u._id)">
                                <i class = "fa fa-trash"></i>
                            </button>
                        </td>
                       <td>
                            <a routerLink = "/admin/reply">
                                <button class = "btn btn-primary">                 
                                     Répondre
                                </button>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>

