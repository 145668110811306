import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {

  eventForm: FormGroup;
  newEvent;
  data: FormData;
  file: any;
  urlImage: String;
  publicId: String;

  constructor(private formBuilder: FormBuilder, private eventService: EventService,
    private router: Router) { 
      this.eventForm = this.formBuilder.group({
        eventTitle: ['', Validators.required],
        eventDesc: ['', Validators.required],
        eventImg: ['', Validators.required]
      })
     }

     uploadChange(event) {
      this.file = event.target.files[0];
      console.log(event);
     }

    addNewEvent(): void {
      this.data = new FormData();
      this.data.append("file", this.file);
      this.data.append("upload_preset", "qjpkv9me");
      this.data.append("cloud_name", "dj9ufxhbc");
      fetch("https://api.cloudinary.com/v1_1/dj9ufxhbc/image/upload",{
              method: 'post',
              body: this.data
          }).then(resp => resp.json())
          .then(data => {
            this.urlImage = data.url;
            this.publicId = data.public_id;
            this.newEvent = {
              eventTitle: this.eventForm.value.eventTitle,
              eventDesc: this.eventForm.value.eventDesc,
              eventImg: this.urlImage,
              eventPublicId: this.publicId
            }
            this.eventService.addNewEvent(this.newEvent).subscribe(() => {
              this.eventForm.reset();
              alert("Event Added Successfully");
              this.router.navigateByUrl("/admin/events");
            }, error => {
              alert('Error adding event!');
            })
        })
    }

  ngOnInit(): void {
  }

}
