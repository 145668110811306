import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  checkLoggedIn: any;

  constructor(private router: Router) { }

  logout() {
    sessionStorage.removeItem('admin');
    sessionStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('admin')) {
      this.checkLoggedIn = JSON.parse(sessionStorage.getItem('admin'));
    } else {
      this.checkLoggedIn = 'NAN';
    }
    if (this.checkLoggedIn == 'NAN') {
      alert('You are not logged in as ADMINISTRATOR');
      this.router.navigate(['/login']);
    }
  }

}
