    <!-- ======= Frequently Asked Questions Section ======= -->
    <section id="faq" class="faq section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Questions fréquemment posées</h2>
          <p>Voulez-vous plus d'informations? Consultez nos questions fréquemment posées ci-dessous.
            Vous ne trouvez pas la réponse à votre question? Contactez notre service client, nous serons heureux de vous aider.</p>
        </div>

        <div class="faq-list">
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">Qu’est-ce qu’un logiciel ERP ? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                <p>
                  Les logiciels ERP sont importants pour les entreprises car ils les aident à mettre en œuvre la planification de leurs ressources.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="200">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">Qui utilise Sage X3 ? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Sage X3 est un logiciel de gestion intégré destiné aux PME et entreprises aux ambitions internationales. Cet ERP se veut évolutif et flexible afin d'accompagner les organisations de manière dédiée
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="300">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">Pourquoi choisir Sage X3 ? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Sage X3 est une solution permettant une gestion d'entreprise plus simple, plus rapide et plus souple
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="400">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">C'est quoi le métier de consultant ? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                <p>
                  Expert et fin stratège, le consultant recherche des solutions pour améliorer le fonctionnement des entreprises, dans des domaines comme l'organisation, la relation client, les ressources humaines, les systèmes d'information, l'environnement, etc.
                </p>
              </div>
            </li>

            <li data-aos="fade-up" data-aos-delay="500">
              <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">Quel est l'objectif d'un ERP ? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                <p>
                  L'objectif de l'ERP est de vous faire gagner en efficacité opérationnelle, en temps et transparence. Un ERP vise à homogénéiser tout le système d'information et les données d'une entreprise. Il se divise en plusieurs modules et sont reliés par une base de données unique.
                </p>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </section>
    <!-- End Frequently Asked Questions Section -->  
