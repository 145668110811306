import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  listServices: Object[] = [];

  constructor(private serviceService: ServiceService) { }

  getListServices() {
    this.serviceService.getListServices().subscribe((services: Object[]) => {
      this.listServices = services;
    }, error => {
      alert("Couldn't retrieve services!");
    })
  }

  ngOnInit(): void {
    this.getListServices();
  }

}
