  <!-- ======= Event Section ======= -->
  <section id="event" class="services section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Evénements</h2>
        <p>Tous les evénements que nous organisons.</p>
      </div>

      <div class="row"> 
        <div class="col-xl-3 col-md-6 d-flex align-items-stretch "  style="align-items: center  ;"
        data-aos="zoom-in" data-aos-delay="100"
        *ngFor = "let e of listEvents">
          <div class="icon-box">
            <div class="icon" align="center">
              <img [src] = "e.eventImg" width="350" height="100"/>
            </div>
            <h4 style="text-align: center;"><a href="">{{e.eventTitle}}</a></h4>
            <p>{{e.eventDesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Event Section -->
