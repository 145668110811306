import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  checkLoggedIn: any;

  constructor(private router: Router) { }

  logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('user')) {
      this.checkLoggedIn = JSON.parse(sessionStorage.getItem('user'));
    } else {
      this.checkLoggedIn = 'NAN';
    }
  }

}
