import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {

  serviceForm: FormGroup;
  newService;
  data: FormData;
  file: any;
  urlImage: String;
  publicId: String;

  constructor(private formBuilder: FormBuilder, private service: ServiceService,
    private router: Router) { 
      this.serviceForm = this.formBuilder.group({
        serviceTitle: ['', Validators.required],
        serviceDesc: ['', Validators.required],
        serviceImg: ['', Validators.required]
      })
     }

     uploadChange(event) {
      this.file = event.target.files[0];
      console.log(event);
     }

    addNewService(): void {
      this.data = new FormData();
      this.data.append("file", this.file);
      this.data.append("upload_preset", "qjpkv9me");
      this.data.append("cloud_name", "dj9ufxhbc");
      fetch("https://api.cloudinary.com/v1_1/dj9ufxhbc/image/upload",{
              method: 'post',
              body: this.data
          }).then(resp => resp.json())
          .then(data => {
            this.urlImage = data.url;
            this.publicId = data.public_id;
            this.newService = {
              serviceTitle: this.serviceForm.value.serviceTitle,
              serviceDesc: this.serviceForm.value.serviceDesc,
              serviceImg: this.urlImage,
              servicePublicId: this.publicId
            }
            this.service.addNewService(this.newService).subscribe(() => {
              this.serviceForm.reset();
              alert("Service Added Successfully");
              this.router.navigateByUrl("/admin/services");
            }, error => {
              alert('Error adding service!');
            })
        })
    }

  ngOnInit(): void {
  }

}
