    <!-- ======= Skills Section ======= -->
    <section id="skills" class="skills">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/skills.png" class="img-fluid" alt="">
          </div>
          
          <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
            <br><br><br>
            <h3>STAR ENGINEERING au cœur de L'IT</h3>
            <p class="fst-italic">
              Nous avons la motivation, l'expertise et le talent en génie logiciel pour faire passer n'importe quelle idée
              de la phase de conception initiale à un produit commercial qui crée de la valeur pour nos clients et ouvre 
              de nouvelles opportunités.
            </p>
           </div>
        </div>

      </div>
    </section><!-- End Skills Section -->

