import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { PartnersComponent } from './components/partners/partners.component';
import { AboutComponent } from './components/about/about.component';
import { SkillsComponent } from './components/skills/skills.component';
import { MainComponent } from './components/main/main.component';
import { NewsComponent } from './components/news/news.component';
import { ServicesComponent } from './components/services/services.component';
import { UpcomingEventComponent } from './components/upcoming-event/upcoming-event.component';
import { EventsComponent } from './components/events/events.component';
import { TeamComponent } from './components/team/team.component';
import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './dashboard/homepage/homepage.component';
import { SidebarComponent } from './dashboard/sidebar/sidebar.component';
import { NavbarComponent } from './dashboard/navbar/navbar.component';
import { StatsComponent } from './dashboard/stats/stats.component';
import { FooterAdminComponent } from './dashboard/footer-admin/footer-admin.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ServicesAdminComponent } from './dashboard/services-admin/services-admin.component';
import { ServiceService } from './services/service/service.service';
import { EventsAdminComponent } from './dashboard/events-admin/events-admin.component';
import { EventService } from './services/event/event.service';
import { AddServiceComponent } from './dashboard/add-service/add-service.component';
import { AddEventComponent } from './dashboard/add-event/add-event.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './dashboard/users/users.component';
import { UserService } from './services/user/user.service';
import { AdminService } from './services/admin/admin.service';
import { NewsAdminComponent } from './dashboard/news-admin/news-admin.component';
import { AddNewsComponent } from './dashboard/add-news/add-news.component';
import { MessagesAdminComponent } from './dashboard/messages-admin/messages-admin.component';
import { UsersAdminComponent } from './dashboard/users-admin/users-admin.component';
import { AddUsersComponent } from './dashboard/add-users/add-users.component';
import { ReplymsgComponent } from './dashboard/replymsg/replymsg.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    PartnersComponent,
    AboutComponent,
    SkillsComponent,
    MainComponent,
    NewsComponent,
    ServicesComponent,
    UpcomingEventComponent,
    EventsComponent,
    TeamComponent,
    ContactComponent,
    FaqComponent,
    FooterComponent,
    HomepageComponent,
    SidebarComponent,
    NavbarComponent,
    StatsComponent,
    FooterAdminComponent,
    LandingPageComponent,
    ServicesAdminComponent,
    EventsAdminComponent,
    AddServiceComponent,
    AddEventComponent,
    RegisterComponent,
    LoginComponent,
    UsersComponent,
    NewsAdminComponent,
    AddNewsComponent,
    MessagesAdminComponent,
    UsersAdminComponent,
    AddUsersComponent,
    ReplymsgComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [ServiceService, EventService, UserService, AdminService],
  bootstrap: [AppComponent]
})
export class AppModule { }
