import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  addNewEvent(event) {
    return this.http.post(`${environment.starEngBackendURL}event/add-event`, event, this.httpOptions);
  }

  getListEvents() {
    return this.http.get(`${environment.starEngBackendURL}event/allEvents`, this.httpOptions);
  }

  deleteEvent(eventId) {
    return this.http.delete(`${environment.starEngBackendURL}event/delete-event/${eventId}`, this.httpOptions);
  }
}
