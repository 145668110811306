import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-upcoming-event',
  templateUrl: './upcoming-event.component.html',
  styleUrls: ['./upcoming-event.component.css']
})
export class UpcomingEventComponent implements OnInit {

  lastEvent: any;

  constructor(private eventService: EventService) { }

  getListEvents() {
    this.eventService.getListEvents().subscribe((events) => {
      this.lastEvent = events[0];
      console.log(this.lastEvent);
    }, error => {
      alert("Error retrieving events");
    })
  }

  ngOnInit(): void {
    this.getListEvents();
  }

}
