<!-- ======= About Us Section ======= -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>à propos de nous</h2>
    </div>

    <div class="row content">
      <div class="col-lg-6">
        <p>
            La société STAR ENGINEERING spécialisée Informatique - logiciels, Progiciels. La société STAR ENGINEERING est basée à Tunis..
        </p>
        <ul>
          <li><i class="ri-check-double-line"></i> Logiciels, sites web et autres</li>
          <li><i class="ri-check-double-line"></i> Bureaux de conseils et assistances</li>
          <li><i class="ri-check-double-line"></i> ERP et solutions pour la performance de l’entreprise</li>
        </ul>
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0">
        <p>
          Depuis sa création en 1996, STAR Engineering a pour mission la diffusion et la mise en place de solutions de gestion 
          visant à améliorer la productivité, la rentabilité et la réactivité des entreprises sur leur marché..
        </p>
        <a href="#" class="btn-learn-more">Apprendre encore plus</a>
      </div>
    </div>

  </div>
</section>
<!-- End About Us Section -->
