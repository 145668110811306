    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">
  
          <div class="section-title">
            <h2>Equipe</h2>
            <p> " Les meilleures choses qui arrivent dans le monde de l’entreprise ne sont pas le résultat du travail d’un seul homme. C’est le travail de toute une équipe. " 

              Steve Jobs</p>
          </div>
          <div class="row">
  
            <div data-aos="zoom-in" data-aos-delay="100">
              <div class="member d-flex align-items-start">
                <div class="pic"><img src="assets/img/team/esprit-dequipe.jpg" class="img-fluid" alt=""></div>
                <div class="member-info">
                  <h4>Star Engineering</h4>
                  <span>Riche de nos talents</span>
                  <p>Notre travail consiste en des projets et nécessite donc un travail d'équipe.
                     Une organisation collaborative nous permet de nous adapter aux nouveaux enjeux et de valoriser la richesse de nos différences et complémentarités. 
                     Ainsi, chacun peut développer ses talents au mieux de ses capacités,
                     développer ses compétences régulièrement et collaborer efficacement.</p>
       
                </div>
              </div>
            </div>
  
          </div>
  <!--
          <div class="row">
  
            <div class="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
              <div class="member d-flex align-items-start">
                <div class="pic"><img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""></div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>Explicabo voluptatem mollitia et repellat qui dolorum quasi</p>
                  <div class="social">
                    <a href=""><i class="ri-twitter-fill"></i></a>
                    <a href=""><i class="ri-facebook-fill"></i></a>
                    <a href=""><i class="ri-instagram-fill"></i></a>
                    <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                  </div>
                </div>
              </div>
            </div>
  
          </div>-->
  
        </div>
      </section><!-- End Team Section -->  
