import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm: FormGroup;
  userToLogin: any;
  user: any;

  constructor(private elementRef: ElementRef, private formBuilder: FormBuilder,
    private userService: UserService, private router: Router) { 
      this.loginForm = formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]]
      })
    }

    login() {
      this.userToLogin = {
        email: this.loginForm.value.email, 
        password: this.loginForm.value.password
         }
         this.userService.loginUser(this.userToLogin).subscribe(data => {
          alert("Logged In Successfully!");
          this.loginForm.reset();
          this.user = data;
          console.log(this.user.userr.role);
          sessionStorage.setItem('token', this.user.token);
          if (this.user.userr.role == 'USER') {
            this.router.navigate(['/']);
            sessionStorage.setItem('user', JSON.stringify(this.user.userr));
          } else {
            this.router.navigate(['/admin']);
            sessionStorage.setItem('admin', JSON.stringify(this.user.userr));
          }
        }, err => {
          alert(err?.error?.msg);
          console.log(err?.error?.msg);
        });
        }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    /* this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = '#3A60D0'; */
  }

}
