<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <div align = "right">
                    <a routerLink = "/admin/events">
                      <button class = "btn btn-primary">
                         Evénements
                      </button>
                    </a>
                </div>
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Ajouter un nouvel événement</h1>
                </div>
                <form [formGroup] = "eventForm">
                    <div class="form-group">
                        <input type="text" formControlName = "eventTitle"
                        class="form-control form-control-user" placeholder="Titre de l'événement">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "eventDesc"
                        class="form-control form-control-user" placeholder="Description de l'évenement">
                    </div>
                    <div class="form-group">
                        <input type="file" formControlName = "eventImg"
                        class="form-control form-control-user" placeholder="Image de l'événement"
                        [value]="urlImage" (change)="uploadChange($event)"/>
                    </div>
                    <a (click)="addNewEvent()" class="btn btn-primary btn-user btn-block">
                        Ajouter un évènement
                    </a>
                </form>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>
