  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">

      <h3 class="logo me-auto"  style="font-size: 24px;" ><a href="/">Star Engineering</a></h3>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Accueil</a></li>
          <li><a class="nav-link scrollto" href="#about">A Propos</a></li>
          <li><a class="nav-link scrollto" href="#portfolio">Actualités</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
          <li><a class="nav-link scrollto" href="#event">Evennements</a></li>
          <li><a class="nav-link scrollto" href="#team">Equipe</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          <li *ngIf = "checkLoggedIn === 'NAN'">
            <a class="getstarted scrollto" routerLink="/login">Se Connecter</a>
          </li>
          <li *ngIf="checkLoggedIn !== 'NAN'">
            <a class="nav-link scrollto">
              <u>Hello {{checkLoggedIn?.username}}</u>
            </a>
          </li>
          <li *ngIf="checkLoggedIn !== 'NAN'" (click)="logout()">
            <a class="nav-link scrollto link-danger" style="cursor: pointer;" title="Logout">
              Logout
            </a>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
