    <!-- ======= Partners Section ======= -->
    <section id="clients" class="clients section-bg">
        <div class="container">
  
          <div class="row" data-aos="zoom-in">
  
     <!--  <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="assets/img/clients/client-1.png" class="img-fluid" alt="">
            </div>-->
  
           <div class="d-flex align-items-center justify-content-center">
             <p>“𝒮𝒯𝒜𝑅 𝐸𝒩𝒢𝐼𝒩𝐸𝐸𝑅𝐼𝒩𝒢 𝓇𝑒𝑔𝑜𝓇𝑔𝑒 𝒹𝑒 𝓉𝒶𝓁𝑒𝓃𝓉𝓈, 𝒶𝓋𝒶𝓃𝓉 𝓉𝑜𝓊𝓉 𝓊𝓃𝑒 𝒶𝓋𝑒𝓃𝓉𝓊𝓇𝑒 𝒽𝓊𝓂𝒶𝒾𝓃𝑒 𝒶𝓊 𝓈𝑒𝓇𝓋𝒾𝒸𝑒 𝒹𝑒 𝓈𝑒𝓈 𝒸𝓁𝒾𝑒𝓃𝓉𝓈. 
              𝐼𝓃𝓉é𝑔𝓇𝒶𝓉𝑒𝓊𝓇 𝐸𝑅𝒫 𝑒𝓉 É𝒹𝒾𝓉𝑒𝓊𝓇 𝒹𝑒 𝓈𝑜𝓁𝓊𝓉𝒾𝑜𝓃𝓈 𝒾𝓃𝒻𝑜𝓇𝓂𝒶𝓉𝒾𝓆𝓊𝑒𝓈 𝓂é𝓉𝒾𝑒𝓇𝓈, 𝓃𝑜𝓊𝓈 𝓅𝓇𝑜𝓅𝑜𝓈𝑜𝓃𝓈 𝓊𝓃𝑒 𝓁𝒶𝓇𝑔𝑒 𝑔𝒶𝓂𝓂𝑒 𝒹𝑒 𝓁𝑜𝑔𝒾𝒸𝒾𝑒𝓁𝓈,
               𝒹𝑒 𝓈𝑜𝓁𝓊𝓉𝒾𝑜𝓃𝓈 𝒶𝓅𝓅𝓁𝒾𝒸𝒶𝓉𝒾𝓋𝑒𝓈 𝑒𝓉 𝒹𝑒 𝓈𝑒𝓇𝓋𝒾𝒸𝑒𝓈 𝓅𝑜𝓊𝓇 𝒶𝒸𝒸𝑜𝓂𝓅𝒶𝑔𝓃𝑒𝓇 𝒶𝓊 𝓂𝒾𝑒𝓊𝓍 𝓃𝑜𝓈 𝒸𝓁𝒾𝑒𝓃𝓉𝓈 𝒹𝒶𝓃𝓈 𝓁𝑒𝓊𝓇 𝓉𝓇𝒶𝓃𝓈𝒻𝑜𝓇𝓂𝒶𝓉𝒾𝑜𝓃 𝒹𝒾𝑔𝒾𝓉𝒶𝓁𝑒.”</p>
            </div>
  
          </div>
  
        </div>
      </section><!-- End Cliens Section -->
  
