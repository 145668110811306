<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <app-stats></app-stats>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>