import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  listUsers: Object[] = [];

  constructor(private adminService: AdminService) { }

  acceptUser(userId) {
    this.adminService.acceptUser(userId).subscribe(data => {
      alert("User Accepted!");
      window.location.reload();
    })
}
verifUser(emails) {
    this.adminService.verifyUser(emails).subscribe(data => {
        alert("Is Valid !");
  })
}
  refuseUser(userId) {
      this.adminService.rejectUser(userId).subscribe(data => {
        alert("User Rejected!");
        window.location.reload();
      })
  }

  getListUsers() {
    this.adminService.getUsers().subscribe((users: Object[]) => {
      this.listUsers = users;
    }, error => {
      alert("Error finding users");
    })
  }

  ngOnInit(): void {
    this.getListUsers();
  }

}
