    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>Contact</h2>
          <p>Nous aimons avoir de vos nouvelles. Si vous êtes intéressé à nous rendre visite, veuillez nous écrire ou nous le dire.</p>
        </div>
        <div class="row">
          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Emplacement:</h4>
                <p>75,Avenue Kheireddine Pacha, Immeuble Pacha Centre, Bloc B 2ème étage, bureau n°5
                    1073 Montplaisir
                    Tunis, Tunisie</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>star@star-consult.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Appel:</h4>
                <p>(+216) 71951508</p>
              </div>

              <iframe src="https://maps.google.com/maps?q=pacha center&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form [formGroup] = "msgForm" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Votre Nom</label>
                  <input type="text" formControlName = "username" name="name" class="form-control" id="name" required>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Votre E-mail</label>
                  <input type="email" formControlName = "email" class="form-control" name="email" id="email" required>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Sujet</label>
                <input type="text" class="form-control" formControlName = "subject" name="subject" id="subject" required>
              </div>
              <div class="form-group">
                <label for="name">Message</label>
                <textarea class="form-control" name="message" formControlName = "content" rows="10" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Chargement</div>
                <div class="error-message"></div>
                <div class="sent-message">Votre message a été envoyé. Merci!</div>
              </div>
             <!-- <div class="text-center"><button type="submit">Envoyer Le Message</button></div>--> 
              <a (click)="SendMSG()" class="btn btn-primary btn-user btn-block">
                Envoyer Le Message
            </a>
            </form>
          </div>

        </div>

      </div>
    </section>
    <!-- End Contact Section -->

