import { Component, OnInit } from '@angular/core';

import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-news-admin',
  templateUrl: './news-admin.component.html',
  styleUrls: ['./news-admin.component.css']
})
export class NewsAdminComponent implements OnInit {

  listNews: Object[] = [];

  constructor(private newsService: NewsService) { }

  deleteNews(newsId) {
    if (confirm("Are you sure you would like to delete this service?")) {
      this.newsService.deleteNews(newsId).subscribe(data => {
        alert("News Deleted!");
        window.location.reload();
      })
    }
  }

  getListNews() {
    this.newsService.getListNews().subscribe((news: Object[]) => {
      this.listNews = news;
    }, error => {
      alert("Error finding news");
    })
  }

  ngOnInit(): void {
    this.getListNews();
  }

}
