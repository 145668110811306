    <!-- ======= Cta Section ======= -->
    <section id="cta" class="cta">
        <div class="container" data-aos="zoom-in">
  
          <div class="row">
            <div class="col-lg-9 text-center text-lg-start">
              <h3>Upcoming Event</h3>
              <p>
                <img [src]="lastEvent?.eventImg" width="300" height="150"/><br/><br/>
                Event Title: {{lastEvent?.eventTitle}}<br/><br/>
                Event Description: {{lastEvent?.eventDesc}}
              </p>
            </div>
            <div class="col-lg-3 cta-btn-container text-center">
              <a class="cta-btn align-middle" href="#"> See Details</a>
            </div>
          </div>
  
        </div>
      </section>
      <!-- End Cta Section -->
  
  
