<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <div align = "right">
                    <a routerLink = "/admin/users">
                      <button class = "btn btn-primary">
                         utilisateur
                      </button>
                    </a>
                </div>
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Ajouter un nouvel utilisateur</h1>
                </div>
                <form [formGroup] = "userForm">
                    <div class="form-group">
                        <input type="text" formControlName = "username"
                        class="form-control form-control-user" placeholder="username de l'utilisateur">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "email"
                        class="form-control form-control-user" placeholder="email de l'utilisateur">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "serialCtr"
                        class="form-control form-control-user" placeholder="Code de contrat en série">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName = "phoneNumber"
                        class="form-control form-control-user" placeholder="phoneNumber de l'utilisateur">
                    </div>
                    <a (click)="addNewUser()" class="btn btn-primary btn-user btn-block">
                        Ajouter un utilisateur
                    </a>
                </form>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>
