import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-events-admin',
  templateUrl: './events-admin.component.html',
  styleUrls: ['./events-admin.component.css']
})
export class EventsAdminComponent implements OnInit {

  listEvents: Object[] = [];

  constructor(private eventService: EventService) { }

  deleteEvent(eventId) {
    if (confirm("Are you sure you would like to delete this service?")) {
      this.eventService.deleteEvent(eventId).subscribe(data => {
        alert("Event Deleted!");
        window.location.reload();
      })
    }
  }

  getListEvents() {
    this.eventService.getListEvents().subscribe((events: Object[]) => {
      this.listEvents = events;
    }, error => {
      alert("Error finding events");
    })
  }

  ngOnInit(): void {
    this.getListEvents();
  }

}
