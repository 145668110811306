import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.css']
})

export class UsersAdminComponent implements OnInit {

  listUsers: Object[] = [];

  constructor(private adminService: AdminService) { }


  getListUsers() {
    this.adminService.getUsers().subscribe((users: Object[]) => {
      this.listUsers = users;
    }, error => {
      alert("Error finding users");
    })
  }
  deleteUsers(userId) {
    if (confirm("Are you sure you would like to delete this user?")) {
      this.adminService.deleteUsers(userId).subscribe(data => {
        alert("User Deleted!");
        window.location.reload();
      })
    }
  }
  ngOnInit(): void {
    this.getListUsers();
  }

}
