<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-navbar></app-navbar>
            <div class="container-fluid">
                <!-- <div align = "right">
                    <a routerLink = "/admin/add-new-service">
                      <button class = "btn btn-primary">
                          Add New Service
                      </button>
                    </a>
                </div> -->
                <h1 class="h3 mb-2 text-gray-800">Utilisateurs</h1>
<p class="mb-4">Utilisateurs qui se sont inscrits à notre application.</p>
<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Nos utilisateurs</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>E-mail</th>
                        <th>Numéro de téléphone</th>
                        <th>Code de contrat en série</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = "let u of listUsers">
                        <td>{{u.username}}</td>
                        <td>{{u.email}}</td>
                        <td>{{u.phoneNumber}}</td>
                        <td>{{u.serialCtr}}</td>
                        <td>
                            <button class = "btn btn-primary" (click)="verifUser(u.email)" title="Verifier"
                            *ngIf="u.isVerified === false && u.role !== 'admin'">
                                <i class = "bi bi-patch-check"></i>
                            </button>
                            <button class = "btn btn-success" (click)="acceptUser(u._id)" title="Accept"
                            *ngIf="u.isVerified === false && u.role !== 'admin'">
                                <i class = "fa fa-check"></i>
                            </button>
                            <button class = "btn btn-danger" (click)="refuseUser(u._id)" title="Refuse"
                            *ngIf="u.isVerified === false && u.role !== 'admin'">
                                <i class = "fa fa-ban"></i>
                            </button>
                            <span style="color: green; font-weight: bold;" *ngIf="u.role === 'admin'">
                                ADMIN
                            </span>
                            <span style="color: green; font-weight: bold;" 
                            *ngIf="u.role !== 'admin' && u.isVerified === true">
                                ACCEPTED
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
            </div>
        </div>
        <app-footer-admin></app-footer-admin>
    </div>
</div>

