import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  listEvents: Object[] = [];;

  constructor(private eventService: EventService) { }

  getAllEvents() {
    this.eventService.getListEvents().subscribe((events: Object[]) => {
      this.listEvents = events;
    }, error => {
      alert("Couldn't retrieve events!");
    })
  }

  ngOnInit(): void {
    this.getAllEvents();
  }

}
