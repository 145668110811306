import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/services/service/service.service';

@Component({
  selector: 'app-services-admin',
  templateUrl: './services-admin.component.html',
  styleUrls: ['./services-admin.component.css']
})
export class ServicesAdminComponent implements OnInit {

  listServices: Object[] = [];

  constructor(private service: ServiceService) { }

  deleteService(serviceId) {
    if (confirm("Are you sure you would like to delete this service?")) {
      this.service.deleteService(serviceId).subscribe(data => {
        alert("Service Deleted!");
        window.location.reload();
      })
    }
  }

  getListServices() {
    this.service.getListServices().subscribe((services: Object[]) => {
      this.listServices = services;
    }, error => {
      alert("Error finding services");
    })
  }

  ngOnInit(): void {
    this.getListServices();
  }

}
