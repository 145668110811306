<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/admin">
        <div class="sidebar-brand-icon rotate-n-15">
            <i class="fas fa-laugh-wink"></i>
        </div>
        <div class="sidebar-brand-text mx-3">Star Engineering</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
        <a class="nav-link" routerLink="/admin">
            <i class="fas fa-fw fa-tachometer-alt"></i>
            <span>Admin Dashboard</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
        Management
    </div>
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/users">
            <i class="fas fa-fw fa-users"></i>
            <span>Utilisateurs</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/usersAdmin">
            <i class="fas fa-fw fa-users"></i>
            <span>Gérer Utilisateurs</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/services">
            <i class="fas fa-fw fa-cog"></i>
            <span>Services</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/events">
            <i class="fas fa-fw fa-calendar"></i>
            <span>Evènements</span></a>
    </li>


</ul>
