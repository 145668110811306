<main id="main">
    <app-partners></app-partners>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-services></app-services>
    <app-upcoming-event></app-upcoming-event>
    <app-events></app-events>
    <app-team></app-team>
    <app-faq></app-faq>
    <app-contact></app-contact>
</main>