import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ReplymsgService } from 'src/app/services/Reply/reply.service';
@Component({
  selector: 'app-replymsg',
  templateUrl: './replymsg.component.html',
  styleUrls: ['./replymsg.component.css']
})
export class ReplymsgComponent implements OnInit {

 
  replyForm: FormGroup;
  newMsg;
  data: FormData;
  constructor(private formBuilder: FormBuilder, private replymsgService: ReplymsgService,
    private router: Router) { 
      this.replyForm = this.formBuilder.group({
        username: ['', Validators.required],
        email: ['', Validators.required],
        subject: ['', Validators.required],
        content: ['', Validators.required]
      })
     }

     SendMsg(): void {
      this.data = new FormData();
       
            this.newMsg = {
              username: this.replyForm.value.username,
              email: this.replyForm.value.email,
              subject: this.replyForm.value.subject,
              content: this.replyForm.value.content
            }
            this.replymsgService.Reply(this.newMsg).subscribe(() => {
              this.replyForm.reset();
              alert("message avec succés");
              this.router.navigateByUrl("/admin/messages");
            }, err => {
              alert("message echoué");
            });
      }
    
    ngOnInit(): void {
    }
  
  }
  